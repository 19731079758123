@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

.App {
  text-align: center;
  margin-bottom: 2%;
  max-width: 100%;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

a {
  color: #f44336;
  text-decoration: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
}

h2 {
  color: #885b5f;
}